/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cloud_logic_custom": [
        {
            "name": "clickToCall",
            "endpoint": "https://v0uau6xwc6.execute-api.ap-south-1.amazonaws.com/staging",
            "region": "ap-south-1"
        },
        {
            "name": "docGenerating",
            "endpoint": "https://o6qbr72vjg.execute-api.ap-south-1.amazonaws.com/staging",
            "region": "ap-south-1"
        },
        {
            "name": "flightBooking",
            "endpoint": "https://h1n85ktscg.execute-api.ap-south-1.amazonaws.com/staging",
            "region": "ap-south-1"
        },
        {
            "name": "paymentProcessing",
            "endpoint": "https://7a1i2w94c1.execute-api.ap-south-1.amazonaws.com/staging",
            "region": "ap-south-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://crfhmvcnzrcuzo4f34phyghwtq.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-tdt2v5mmyndyzjb5lhwtsw6k3m",
    "aws_cognito_identity_pool_id": "ap-south-1:8393dd9d-7882-4e3a-8594-ff7675bc5163",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_MjRpVNlNj",
    "aws_user_pools_web_client_id": "1nrf0neesl17v3d1i6v4hstl4j",
    "oauth": {
        "domain": "vw0lmo3cvswd-staging.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev-admin.trip-cart.com/,https://develop.d25gq9rpd96nxv.amplifyapp.com/",
        "redirectSignOut": "http://localhost:3000/,https://dev-admin.trip-cart.com/,https://develop.d25gq9rpd96nxv.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "tripcart-uploads111549-staging",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
