/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFAQ = /* GraphQL */ `
  query GetFAQ($id: ID!) {
    getFAQ(id: $id) {
      id
      question
      answer
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFAQS = /* GraphQL */ `
  query ListFAQS(
    $filter: ModelFAQFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        otherExtra
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPolicy = /* GraphQL */ `
  query GetPolicy($id: ID!) {
    getPolicy(id: $id) {
      id
      title
      descriptionLines
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPolicies = /* GraphQL */ `
  query ListPolicies(
    $filter: ModelPolicyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPolicies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        descriptionLines
        otherExtra
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStaticPackage = /* GraphQL */ `
  query GetStaticPackage($id: ID!) {
    getStaticPackage(id: $id) {
      id
      title
      duration
      description
      itinerary
      pricing
      locations
      addOns
      inclusions
      exclusions
      terms
      tags
      isActive
      isApproved
      approvedBy
      destinationId
      createdBy
      otherExtra
      multilineDesc
      isMultilineDesc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStaticPackages = /* GraphQL */ `
  query ListStaticPackages(
    $filter: ModelStaticPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaticPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        duration
        description
        itinerary
        pricing
        locations
        addOns
        inclusions
        exclusions
        terms
        tags
        isActive
        isApproved
        approvedBy
        destinationId
        createdBy
        otherExtra
        multilineDesc
        isMultilineDesc
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeadPackage = /* GraphQL */ `
  query GetLeadPackage($id: ID!) {
    getLeadPackage(id: $id) {
      id
      title
      duration
      tripSummary
      tripCordinator
      markupDetails
      travellerDetails
      itinerary
      hotels
      transports
      flights
      totalPrice
      travelDate
      locations
      paymentOptions
      packageUrl
      inclusions
      exclusions
      extraInfo
      terms
      isActive
      isApproved
      approvedBy
      destinationId
      leadId
      createdBy
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLeadPackages = /* GraphQL */ `
  query ListLeadPackages(
    $filter: ModelLeadPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeadPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        duration
        tripSummary
        tripCordinator
        markupDetails
        travellerDetails
        itinerary
        hotels
        transports
        flights
        totalPrice
        travelDate
        locations
        paymentOptions
        packageUrl
        inclusions
        exclusions
        extraInfo
        terms
        isActive
        isApproved
        approvedBy
        destinationId
        leadId
        createdBy
        otherExtra
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDestination = /* GraphQL */ `
  query GetDestination($id: ID!) {
    getDestination(id: $id) {
      id
      name
      country
      cities
      places
      description
      isActive
      photos
      banners
      terms
      notices
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDestinations = /* GraphQL */ `
  query ListDestinations(
    $filter: ModelDestinationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDestinations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country
        cities
        places
        description
        isActive
        photos
        banners
        terms
        notices
        otherExtra
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      name
      department
      mobile
      altMobile
      address
      birthdate
      joiningDate
      gender
      isActive
      email
      profilePic
      roleId
      emergencyContactName
      emergencyContactNumber
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        department
        mobile
        altMobile
        address
        birthdate
        joiningDate
        gender
        isActive
        email
        profilePic
        roleId
        emergencyContactName
        emergencyContactNumber
        otherExtra
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      permissions
      isAdmin
      description
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        permissions
        isAdmin
        description
        otherExtra
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDMCAccount = /* GraphQL */ `
  query GetDMCAccount($id: ID!) {
    getDMCAccount(id: $id) {
      id
      name
      contacts
      address
      isActive
      bankDetails
      destinationId
      description
      otherExtra
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDMCAccounts = /* GraphQL */ `
  query ListDMCAccounts(
    $filter: ModelDMCAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDMCAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contacts
        address
        isActive
        bankDetails
        destinationId
        description
        otherExtra
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      name
      email
      mobile
      altMobile
      city
      userId
      travelDate
      destination
      adults
      children
      infants
      description
      schedule
      followUps
      status
      isActive
      otherExtra
      assignedTo
      assignedBy
      leadPackages {
        items {
          id
          title
          duration
          tripSummary
          tripCordinator
          markupDetails
          travellerDetails
          itinerary
          hotels
          transports
          flights
          totalPrice
          travelDate
          locations
          paymentOptions
          packageUrl
          inclusions
          exclusions
          extraInfo
          terms
          isActive
          isApproved
          approvedBy
          destinationId
          leadId
          createdBy
          otherExtra
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        mobile
        altMobile
        city
        userId
        travelDate
        destination
        adults
        children
        infants
        description
        schedule
        followUps
        status
        isActive
        otherExtra
        assignedTo
        assignedBy
        leadPackages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getViaOrder = /* GraphQL */ `
  query GetViaOrder($id: ID!) {
    getViaOrder(id: $id) {
      id
      reference
      viaOrderRef
      ticketUrl
      orderAmount
      amountToCharge
      convenienceFee
      discount
      isFlightBooked
      userId
      tripDetails
      payments {
        items {
          id
          transactionId
          amount
          status
          channel
          origin
          type
          orderId
          optionId
          otherExtra
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      travellersData
      deliveryData
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listViaOrders = /* GraphQL */ `
  query ListViaOrders(
    $filter: ModelViaOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViaOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reference
        viaOrderRef
        ticketUrl
        orderAmount
        amountToCharge
        convenienceFee
        discount
        isFlightBooked
        userId
        tripDetails
        payments {
          nextToken
          __typename
        }
        travellersData
        deliveryData
        otherExtra
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTripOrder = /* GraphQL */ `
  query GetTripOrder($id: ID!) {
    getTripOrder(id: $id) {
      id
      totalAmount
      isPaymentComplete
      leadId
      packageId
      tripRef
      isPaymentOption
      paymentOptions
      prevPayOptions
      payments {
        items {
          id
          transactionId
          amount
          status
          channel
          origin
          type
          orderId
          optionId
          otherExtra
          createdBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTripOrders = /* GraphQL */ `
  query ListTripOrders(
    $filter: ModelTripOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTripOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        totalAmount
        isPaymentComplete
        leadId
        packageId
        tripRef
        isPaymentOption
        paymentOptions
        prevPayOptions
        payments {
          nextToken
          __typename
        }
        otherExtra
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      transactionId
      amount
      status
      channel
      origin
      type
      orderId
      optionId
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        transactionId
        amount
        status
        channel
        origin
        type
        orderId
        optionId
        otherExtra
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVoucher = /* GraphQL */ `
  query GetVoucher($id: ID!) {
    getVoucher(id: $id) {
      id
      orderId
      summary
      hotels
      flights
      otherDocs
      guests
      cordinators
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVouchers = /* GraphQL */ `
  query ListVouchers(
    $filter: ModelVoucherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVouchers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        summary
        hotels
        flights
        otherDocs
        guests
        cordinators
        otherExtra
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyInfo = /* GraphQL */ `
  query GetCompanyInfo($id: ID!) {
    getCompanyInfo(id: $id) {
      id
      legalInfo
      bankingInfo
      contacts
      websiteManagement
      salesMarkUp
      currentPackageInfo
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanyInfos = /* GraphQL */ `
  query ListCompanyInfos(
    $filter: ModelCompanyInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        legalInfo
        bankingInfo
        contacts
        websiteManagement
        salesMarkUp
        currentPackageInfo
        otherExtra
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHotel = /* GraphQL */ `
  query GetHotel($id: ID!) {
    getHotel(id: $id) {
      id
      name
      contacts
      address
      email
      phone
      website
      destinationId
      city
      description
      isActive
      terms
      notices
      services
      photos
      roomTypes
      checkInTime
      checkOutTime
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHotels = /* GraphQL */ `
  query ListHotels(
    $filter: ModelHotelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHotels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contacts
        address
        email
        phone
        website
        destinationId
        city
        description
        isActive
        terms
        notices
        services
        photos
        roomTypes
        checkInTime
        checkOutTime
        startMonth
        endMonth
        otherExtra
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDay = /* GraphQL */ `
  query GetDay($id: ID!) {
    getDay(id: $id) {
      id
      title
      address
      destinationId
      city
      description
      isActive
      totalPrice
      transport
      time
      duration
      meal
      terms
      notices
      services
      activities
      photos
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDays = /* GraphQL */ `
  query ListDays(
    $filter: ModelDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        address
        destinationId
        city
        description
        isActive
        totalPrice
        transport
        time
        duration
        meal
        terms
        notices
        services
        activities
        photos
        startMonth
        endMonth
        otherExtra
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTransport = /* GraphQL */ `
  query GetTransport($id: ID!) {
    getTransport(id: $id) {
      id
      title
      fromAddress
      destinationId
      from
      to
      toAddress
      description
      isActive
      totalPrice
      startMonth
      endMonth
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTransports = /* GraphQL */ `
  query ListTransports(
    $filter: ModelTransportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        fromAddress
        destinationId
        from
        to
        toAddress
        description
        isActive
        totalPrice
        startMonth
        endMonth
        otherExtra
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      address
      mobile
      city
      email
      isActive
      otherExtra
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        address
        mobile
        city
        email
        isActive
        otherExtra
        createdBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
